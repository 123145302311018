<template>
    <nav class="navbar bg-dark">
        <div class="container-fluid ">
            <a class="navbar-brand navzataone" href="/">
              <!-- <img src="./assets/logo.png" alt="Logo" width="30" height="24" class="d-inline-block align-text-top"> -->
              <span class="navbar-brand mb-0 h1 navzataone text-light">zataone</span>            
            </a>
        </div>
    </nav>
    <!-- <nav>
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link> |
        <router-link to="/detail">Detail</router-link>
      </nav> -->
    <router-view/>
</template>

<style>
#app {
    font-family: Georgia, Times, 'Times New Roman', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #2c3e50;
    width: 100%;
    min-height: 100vh;
}

.navzataone {
  margin-left: 12%;
}
/* 
nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
