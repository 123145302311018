<template>
    <div class="home">
        <div class="container text-center p-2 mt-4">
            <div class="row">
                <div class="col-sm">
                    <div class=" text-black">
                        <h6 class="lh-base">Hi.. Everyone 👋 <br> <span class="">I,m Zain Ahmad Taufik</span></h6>
                    </div>
                    <div class="text-center position-relative">
                        <img src="../assets/zain3.png" class="myImage" alt="...">
                    </div>
                    <div class="mediaSosial">
                        <a class="media " href="https://www.twitter.com/zain_at1/" target="blank" role="button"><img class="sosial twitter" src="../assets/twitter.png" alt="Twitter zain_at1"></a>
                        <a class="media " href="https://www.instagram.com/zain_at1/" target="blank" role="button"><img class="sosial ig" src="../assets/instagram.png" alt="Instagram zain_at1"></a>
                        <a class="media " href="https://www.linkedin.com/in/zain-at1/" target="blank" role="button"><img class="sosial in" src="../assets/linkedin.png" alt="Linkedin zain"></a>
                        <a class="media " href="https://mail.google.com/mail/?view=cm&fs=1&to=zataone.id@gmail.com" target="blank" role="button"><img class="sosial email" src="../assets/email.png" alt="Email zain"></a>
                    </div>
                </div>
                <div class="col-sm-4 text-black mt-5">
                    <div class="job">
                        <h4><u>Web Development</u></h4>
                        <p>Backend Developer | Fullstack Developer</p>
                    </div>
                    <div class="education">
                        <h4><u>Education</u></h4>
                        <p>S1 - Informatika <br>Universitas Ahmad Dahlan</p>
                    </div>
                    <div class="work">
                        <h4><u>Work Experience</u></h4>
                        <p>SEVEN INC.</p>
                        <img class="imgSertif" src="../assets/seven.jpeg" alt="Seven inc.">
                    </div>
                </div>
                <div class="col-lg-4 mt-5">
                    <div class="myProjek text-black p-4">
                        <h4><u>My Project</u></h4>
                        <div class="card bg-dark cardDetail">
                            <img src="../assets/thum1.jpg" class="card-img-top " alt="...">
                            <div class="card-body text-light">
                                <h5>Web Profil Geogle Mulusan</h5>
                                <p class="card-text">I work as a fullstack developer<br> <a href="/appgeogle" style="font-size:12px">Details</a></p>
                            </div>
                        </div>
                        <a href="/about" class="btn btn-primary mt-4">More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'


export default {
    name: 'HomeView',
    components: {
        // HelloWorld
    }
}
</script>


<style scoped>

/* .home {
    font-family: 'Salsa', cursive;
} */

.textui{
    color: antiquewhite;
}


.myImage {
    width: 250px;
    height: 300px;
    margin-bottom: 10px;
}

.imgSertif{
    width: 200px;
    transition: all 0.3s ease;
}

.imgSertif:hover{
    transform: scale(1.05);
}

.media {
    float: center;
    margin-top: 20px;
    margin-right: 10px;
}

.sosial {
    width: 30px;
    height: 30px;
    transition: all 0.3s ease;
}

.sosial:hover {
    border-radius: 5px;
    transform: scale(1.05);
}

.cardDetail {
    box-shadow: 0 10px 5px rgba(13, 13, 13, 0.578);
    transition: all 0.3s ease;
}

.cardDetail:hover {
    transform: scale(1.05);
}

.cardDetail p {
    font-size: 14px;
    color: rgb(189, 189, 189);
}

.twitter {
    background: rgb(29, 161, 242);
    box-shadow: 0 10px 5px rgba(13, 13, 13, 0.578);
    border-radius: 5px;
}

.ig {
    background: rgb(195, 42, 163);
    box-shadow: 0 10px 5px rgba(13, 13, 13, 0.578);
    border-radius: 5px;
}

.in {
    background: rgb(0, 119, 181);
    box-shadow: 0 10px 5px rgba(13, 13, 13, 0.578);
    border-radius: 5px;
}

.email {
    background: #EA4335;
    box-shadow: 0 10px 5px rgba(13, 13, 13, 0.578);
    border-radius: 5px;
}

.btn {
    transition: all 0.3s ease;
}

.btn:hover {
    transform: scale(1.05);
}
</style>